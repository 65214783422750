import React, { useState } from 'react';
import { CreateDestinationRecord } from '../../../types/global';
import { API_ROUTE } from '../../lib/env';
interface UploadStepProps {
    destRecord: CreateDestinationRecord;
    setDestRecord: (destRecord: CreateDestinationRecord) => void;
    setIsNextDisabled: (isNextDisabled: boolean) => void;
}


const UploadStep = ({ destRecord, setDestRecord, setIsNextDisabled }: UploadStepProps) => {
    const [selectedFiles, setSelectedFiles] = useState<File[] | null>(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadStatus, setUploadStatus] = useState('');

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;

        const filesArray = Array.from(event.target.files);
        setSelectedFiles(filesArray);
    };

    const handleFileUpload = () => {
        if (!selectedFiles) return;

        const formData = new FormData();
        selectedFiles.forEach(file => formData.append('files', file));

        const xhr = new XMLHttpRequest();

        xhr.upload.onprogress = (event) => {
            if (event.lengthComputable) {
                const percentCompleted = Math.round((event.loaded * 100) / event.total);
                setUploadProgress(percentCompleted);
            }
        };

        xhr.onload = () => {
            if (xhr.status === 200) {
                setUploadStatus('Upload successful!');

                destRecord.config = JSON.parse(xhr.responseText);
                setDestRecord(destRecord);
                setIsNextDisabled(false);
            } else {
                setUploadStatus('Upload failed.');
                try {
                    let response = JSON.parse(xhr.responseText);
                    if (response.detail) {
                        alert(response.detail);
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        };

        xhr.onerror = (event) => {
            setUploadStatus('Upload failed.');
            try {
                let response = JSON.parse(xhr.responseText);
                if (response.detail) {
                    alert(response.detail);
                }
            } catch (error) {
                console.error(error);
            }
        };

        xhr.open('POST', `${API_ROUTE}/admin/schema/multi/validate`, true);
        xhr.setRequestHeader('enctype', 'multipart/form-data');

        xhr.send(formData);
    };

    return (
        <div style={{ height: '100%' }}>
            <input type="file" onChange={handleFileChange} />
            <button onClick={handleFileUpload}>Upload</button>
            {selectedFiles && (
                <div>
                    <div style={{ width: '100%', backgroundColor: '#f3f3f3', border: '1px solid #ccc' }}>
                        <div
                            style={{
                                width: `${uploadProgress}%`,
                                height: '24px',
                                backgroundColor: uploadProgress === 100 ? '#4caf50' : '#2196f3',
                                transition: 'width 0.5s'
                            }}
                        />
                    </div>
                    <p>{uploadProgress}%</p>
                    <p>{uploadStatus}</p>
                </div>
            )}
        </div>
    );
};

export default UploadStep;
