import React, { useEffect, useState } from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import UploadStep from './Upload';
import ManualHeadersStep from './ManualHeaders';
import ConfigureStep from './Configure';
import { CreateDestinationRecord } from '../../../types/global';
import pb from '../../lib/pocketbase';
import { API_ROUTE } from '../../lib/env';

const steps = ['Upload file', 'Confirm headers', 'Configure'];


const HorizontalStepper = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [isNextDisabled, setIsNextDisabled] = useState(true); // New state for next button
    const [destRecord, setDestRecord] = useState<CreateDestinationRecord>({
        name: "",
        config: [],
        type: "local",
        token_id: "",
        active: false,
    });

    const handleNext = () => {
        // user clicked finish

        if (activeStep === steps.length - 1) {
            fetch(`${API_ROUTE}/admin/account/destinations/add`, {
                method: "POST",
                body: JSON.stringify(destRecord),
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${pb.authStore.token}`,
                },
            }).then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error("Failed to create destination");
            }
            ).then((response) => {
                // Redirect to home page
                console.log(response);

                window.location.href = "/";

            }).catch((error) => {
                console.error(error);
            });
            return;
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const getStepContent = (step: number) => {
        switch (step) {
            case 0:
                return <UploadStep destRecord={destRecord} setDestRecord={setDestRecord} setIsNextDisabled={setIsNextDisabled} />;
            case 1:
                if (!destRecord.config) return (<Typography>Destination Record not found</Typography>);
                return <ManualHeadersStep destRecord={destRecord} setDestRecord={setDestRecord} />;
            case 2:
                if (!destRecord.config) return (<Typography>File ID not found</Typography>);
                return <ConfigureStep destRecord={destRecord} setDestRecord={setDestRecord} setIsNextDisabled={setIsNextDisabled} />;
            default:
                return 'Unknown step';
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', overflow: 'auto' }}>
            <Box sx={{ flexShrink: 0 }}>
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => (
                        <Step key={index}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>
            <Box sx={{ flexGrow: 1, overflow: 'hidden', height: '100%' }}>
                {activeStep === steps.length ? (
                    <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography>All steps completed - you&apos;re finished</Typography>
                        <Button onClick={handleReset} sx={{ mt: 2 }}>Reset</Button>
                    </Box>
                ) : (
                    <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', height: '100%', overflow: 'hidden', marginTop: '0' }}>
                        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', height: '100%', overflow: 'hidden' }}>
                            {getStepContent(activeStep)}
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                            <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                            >
                                Back
                            </Button>
                            <Button
                                disabled={isNextDisabled}
                                variant="contained"
                                onClick={handleNext}
                            >
                                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                            </Button>
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>

    );
};

export default HorizontalStepper;
