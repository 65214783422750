export const ToastDisplay = () => {
    return (
        <>
            <p className="text-md">Copied to clipboard !</p>
            <div className="warning-banner">
                <p>
                    <b>Don't forget to add the origin</b>
                </p>
            </div>
        </>
    );
}