// src/components/Sidebar.tsx
import React, { useState } from 'react';
import './Sidebar.css'; // Import the CSS file for styling

interface SidebarProps {
  children: React.ReactNode;
}
const Sidebar: React.FC<SidebarProps> = ({ children }) => {
  const [isSidebarMinimized, setIsSidebarMinimized] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarMinimized(!isSidebarMinimized);
  };

  return (
    <div className={`layout ${isSidebarMinimized ? 'sidebar-minimized' : ''}`}>
    <div className={`sidebar ${isSidebarMinimized ? 'minimized' : ''}`}>
      <button onClick={toggleSidebar} className="menu-btn">
        ☰
      </button>
      {!isSidebarMinimized && (
        <ul>
          <li><a href="/">Home</a></li>
          <li><a href="/destination">Create Importer</a></li>
          <li><a href="/files">Customer Files</a></li>
          <li><a href="/settings">Settings</a></li>
          <li><a href="/docs">Docs</a></li>
        </ul>
      )}
    </div>
    <div className="content">
      {children}
    </div>
  </div>
  );
};

export default Sidebar;
