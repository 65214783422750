import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';
import CustomHandle from './CustomHandle';
import { EleganNodeData } from '../Analysis';

interface ConnectNodeProps {
    data: EleganNodeData;
}


const UpperCaseNode = ({ data }: ConnectNodeProps) => {
    return (
        <>
            <CustomHandle
                type="target"
                position={Position.Left}
                style={{ background: '#555' }}
                isConnectable={data.inputNodeCount}
            />
            <div className="node-container">
                <div className="node-bio">
                    {data.label}
                </div>
            </div>
            <CustomHandle type="source" position={Position.Right} style={{ background: '#555' }} isConnectable={data.outputNodeCount} />
        </>
    );
};

export default memo(UpperCaseNode);