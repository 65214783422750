import React from 'react';

const SettingsPage: React.FC = () => {
  return (
    <>
      <h2>Settings</h2>
      <h3>Destinations</h3>
      <a href="/destination">Add destination</a>
    </>
  );
};

export default SettingsPage;
