import React, { useState, useEffect } from 'react';
import { CreateDestinationRecord } from '../../../types/global';
import pb from '../../lib/pocketbase';
import { API_ROUTE } from '../../lib/env';

interface ConfigureStepProps {
    destRecord: CreateDestinationRecord;
    setDestRecord: (destRecord: CreateDestinationRecord) => void;
    setIsNextDisabled: (isNextDisabled: boolean) => void;
}

const trimString = (str: string): string => {
    // Check if the string starts and ends with quotes
    if (str.startsWith('"') && str.endsWith('"')) {
        // Remove the leading and trailing quotes
        return str.substring(1, str.length - 1);
    }
    // Return the original string if no quotes are found
    return str;
};

const ConfigureStep: React.FC<ConfigureStepProps> = ({ destRecord, setDestRecord, setIsNextDisabled }) => {
    const [websiteURL, setWebsiteURL] = useState('https://');
    const [isURLValid, setIsURLValid] = useState(true);
    const [apiKey, setApiKey] = useState<string>('');
    const [isNameValid, setIsNameValid] = useState(false);

    useEffect(() => {
        setIsNextDisabled(!apiKey);
    })

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.value;
        setDestRecord({ ...destRecord, name });

        // Validate the name
        if (name.trim() === '' || name.length < 3) {
            setIsNameValid(false);
        } else {
            setIsNameValid(true);
        }
    };

    const isValidUrl = (url: string): boolean => {
        try {
            new URL(url);
            return true;
        } catch (e) {
            return false;
        }
    };

    const handleURLChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const url = e.target.value;
        setWebsiteURL(url);

        // Creates a text box that will turn red if the URL is invalid
        setIsURLValid(isValidUrl(url));
    };

    const createApiKey = async () => {
        if (isURLValid && websiteURL && isNameValid && destRecord.name.length >= 3) {


            fetch(`${API_ROUTE}/admin/account/origins/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${pb.authStore.token}`
                },
                body: JSON.stringify({ origin: websiteURL }),
            })
                .then((response) => response.json())
                .then((response) => {

                    // Removes the "" from the string
                    let trimmedId = trimString(response.id);

                    setApiKey(trimmedId);

                    setDestRecord({ ...destRecord, token_id: trimmedId });
                });
        }
    };

    const deleteApiKey = async () => {
        if (apiKey) {
            pb.collection("origins").delete(apiKey).then(() => {
                setApiKey('');
                setDestRecord({ ...destRecord, token_id: '' });
            });
        }
    };

    useEffect(() => {
        setIsURLValid(isValidUrl(''));
    }, []);

    return (
        <div>
            <label htmlFor="destinationName">Destination Name:</label>
            <input
                type="text"
                id="destinationName"
                value={destRecord.name}
                onChange={handleNameChange}
                style={{ borderColor: isNameValid ? 'initial' : 'red' }}
            />
            {!isNameValid && <p style={{ color: 'red' }}>Name must be at least 3 characters long.</p>}
            <br />
            <label htmlFor="websiteURL">Origin URL:</label>
            <input
                type="text"
                id="websiteURL"
                value={websiteURL}
                onChange={handleURLChange}
                style={{ borderColor: isURLValid ? 'initial' : 'red' }}
            />
            {!isURLValid && <p style={{ color: 'red' }}>Please enter a valid URL.</p>}
            <br />

            {apiKey && (
                <div style={{ border: '1px solid black', marginTop: '10px', padding: '10px' }}>
                    <h3>API Key:</h3>
                    <pre>{JSON.stringify(apiKey, null, 2)}</pre>
                    <span className='muted'>This is your API key for URL {websiteURL}, don't worry it will be in the sample code we provide automatically</span>
                    <br />
                    <button onClick={deleteApiKey} style={{ marginTop: '10px', backgroundColor: 'red', color: 'white' }}>Delete key</button>
                </div>
            )}
            <button onClick={createApiKey} disabled={!!apiKey || !isNameValid || destRecord.name.length < 3}>Generate key</button>
        </div>
    );
};

export default ConfigureStep;
