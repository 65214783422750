// src/App.tsx
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import Files from './pages/Files';
import Settings from './pages/Settings';
import Docs from './pages/Docs';
import pb from './lib/pocketbase';
import { QueryClient, QueryClientProvider } from 'react-query'
import Flow from './pages/Flow/Flow';
import Sidebar from './components/Sidebar';
import './App.css';
import Destination from './pages/Destination/Destination';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ProtectedRoute = ({ element }: { element: React.ReactNode }) => {
  return pb.authStore.isValid ? <Sidebar>{element}</Sidebar> : <Navigate to="/login" />;
};

const queryClient = new QueryClient();
const App: React.FC = () => {
  const notifyToast = () => toast("Wow so easy !");

  return (
    <QueryClientProvider client={queryClient}>
      <div>
        <Routes>
          <Route path="/" element={<ProtectedRoute element={<Home />} />} />
          <Route path="/files" element={<ProtectedRoute element={<Files />} />} />
          <Route path="/settings" element={<ProtectedRoute element={<Settings />} />} />
          <Route path="/docs" element={<ProtectedRoute element={<Docs />} />} />
          <Route path="/flow" element={<Flow />} />
          <Route path='/destination' element={<ProtectedRoute element={<Destination />} />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </div>
      <ToastContainer position='bottom-right' />
    </QueryClientProvider>
  );
}


export default App;
