import React, { useEffect, useState } from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import UploadStep from './Upload';
import ManualHeadersStep from './ManualHeaders';
import AnalysisStep from './Analysis/Analysis';
import SubmitStep from './Submit';
import { DestinationRecord } from '../../../types/global';
import pb from '../../lib/pocketbase';
import './Flow.css'

export interface SchemaTableType {
  rows: string[][];
  headers: string[];
  errors: {
    errors: {
      schemaIndex: number,
      columnIndex: number,
      error: string
    }[],
    row: string[]
  }[]
}


const steps = ['Upload file', 'Confirm headers', 'Analysis', 'Submit'];

const HorizontalStepper = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [flowId, setFlowId] = useState<string | null>(null);
  const [isNextDisabled, setIsNextDisabled] = useState(true); // New state for next button
  const [destRecord, setDestRecord] = useState<DestinationRecord | null>(null);
  const [communicateContent, setCommunicateContent] = useState<SchemaTableType[]>([]);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const destId = urlSearchParams.get('destination_id');
  const apiKey = urlSearchParams.get('api_key');

  useEffect(() => {
    if (!destId) return;

    pb.collection("destinations").getOne<DestinationRecord>(destId)
      .then((destination) => {
        if (!destination) return;

        setDestRecord(destination);
      })
  }, [destId]);

  if (!destId || !apiKey) {
    return (
      <Typography>Destination ID or API Key not found</Typography>
    );
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <UploadStep setFlowId={setFlowId} setIsNextDisabled={setIsNextDisabled} />;
      case 1:
        if (!flowId) return (<Typography>File ID not found</Typography>);
        if (!destRecord) return (<Typography>Destination Record not found</Typography>);
        return <ManualHeadersStep flowId={flowId} destRecord={destRecord} apiKey={apiKey} setIsNextDisabled={setIsNextDisabled} />;
      case 2:
        if (!flowId) return (<Typography>File ID not found</Typography>);
        return <AnalysisStep flowId={flowId} destRecord={destRecord} communicateContent={communicateContent} setCommunicateContent={setCommunicateContent} />;
      case 3:
        if (!flowId) return (<Typography>File ID not found</Typography>);
        return <SubmitStep flowId={flowId} />;
      default:
        return 'Unknown step';
    }
  };

  return (
    <div className="flow-container">
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', overflow: 'auto', margin: '20px' }}>
        <Box sx={{ flexShrink: 0 }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={index}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <Box sx={{ flexGrow: 1, overflow: 'hidden', height: '100%' }}>
          {activeStep === steps.length ? (
            <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography>All steps completed - you&apos;re finished</Typography>
              <Button onClick={handleReset} sx={{ mt: 2 }}>Reset</Button>
            </Box>
          ) : (
            <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', height: '100%', overflow: 'hidden', marginTop: '0' }}>
              <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', height: '100%', overflow: 'hidden' }}>
                {getStepContent(activeStep)}
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Button
                  disabled={isNextDisabled}
                  variant="contained"
                  onClick={handleNext}
                >
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default HorizontalStepper;
