import React, { useEffect, useState } from "react";
import { SchemaTableType } from "../Flow";
import { Tooltip  } from "react-tooltip";


interface ValidationViewProps {
    communicateContent: SchemaTableType[];
}

interface EditingCellState {
    fileIndex: number;
    rowIndex: number;
    dataIndex: number;
}

interface StringToStringMap {
    [key: string]: string;
}

const ValidationView = ({ communicateContent }: ValidationViewProps) => {
    const [editingCell, setEditingCell] = useState<EditingCellState | null>(null);
    const [cellValues, setCellValues] = useState<StringToStringMap>({});
    

    const handleDoubleClick = (fileIndex:number, rowIndex:number, dataIndex:number, currentValue:string) => {
        setEditingCell({ fileIndex, rowIndex, dataIndex });
        setCellValues({ ...cellValues, [`${fileIndex}-${rowIndex}-${dataIndex}`]: currentValue });
    };

    const handleInputChange = (fileIndex:number, rowIndex:number, dataIndex:number, event: React.ChangeEvent<HTMLInputElement>) => {
        setCellValues({ ...cellValues, [`${fileIndex}-${rowIndex}-${dataIndex}`]: event.target.value });
    };

    const handleKeyDown = (fileIndex: number, rowIndex: number, dataIndex: number, event: React.KeyboardEvent<HTMLInputElement> ) => {
        if (event.key === 'Enter') {
            const target = event.target as HTMLInputElement;

            const value = target.value.trim();

            if (value === '') {
                // Remove the cell from cellValues if the input is empty
                const updatedCellValues = { ...cellValues };
                delete updatedCellValues[`${fileIndex}-${rowIndex}-${dataIndex}`];
                setCellValues(updatedCellValues);
            }

            setEditingCell(null);
        }
    };

    const handleBlur = () => {
        setEditingCell(null);
    };


    useEffect(() => {
        console.log(cellValues);
    }, [cellValues]);

    return (
        <div style={{overflow: 'auto'}}>
            {
                communicateContent.map((file: SchemaTableType, fileIndex: number) => (
                    <table key={`table_${fileIndex}`}>
                        <thead>
                            <tr>
                                {file.headers.map((header: string, headerIndex: number) => (
                                    <th key={`table_${fileIndex}_header_${headerIndex}`}>{header}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                        {Object.entries(file.errors).map(([rowKey, errorObj], rowIndex) => (
                            <tr key={`table_${fileIndex}_row_${rowIndex}`}>
                                {errorObj.row.map((data, dataIndex) => {
                                    // Check if this cell has an error
                                    const errorMessage = errorObj.errors.find(
                                        (error) => error.columnIndex === dataIndex
                                    )?.error;

                                    const cellId = `row-${rowIndex}-cell-${dataIndex}`;
                                    const isEditing = editingCell && 
                                                      editingCell.fileIndex === fileIndex &&
                                                      editingCell.rowIndex === rowIndex &&
                                                      editingCell.dataIndex === dataIndex;

                                    return (
                                        <td
                                            id={cellId}
                                            key={dataIndex}
                                            data-tooltip-delay-show={0}
                                            style={{ 
                                                backgroundColor: errorMessage ? 'red' : 'inherit'
                                            }}
                                            onDoubleClick={() => handleDoubleClick(fileIndex, rowIndex, dataIndex, data)}
                                        >
                                            {isEditing ? (
                                                <input 
                                                    type="text"
                                                    value={cellValues[`${fileIndex}-${rowIndex}-${dataIndex}`] || data}
                                                    onChange={(e) => handleInputChange(fileIndex, rowIndex, dataIndex, e)}
                                                    onKeyDown={(e) => handleKeyDown(fileIndex, rowIndex, dataIndex, e)}
                                                    onBlur={handleBlur}
                                                    autoFocus
                                                />
                                            ) : (
                                                <>
                                                    {cellValues[`${fileIndex}-${rowIndex}-${dataIndex}`] || data}
                                                    {errorMessage && (
                                                        <Tooltip 
                                                            place="top" 
                                                            anchorSelect={`#${cellId}`}
                                                            id={`error_${fileIndex}_${rowIndex}_${dataIndex}`}
                                                            delayShow={500}
                                                        >
                                                            {errorMessage}
                                                        </Tooltip>
                                                    )}
                                                </>
                                            )}
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                        </tbody>
                    </table>
                ))
            }
        </div>
    );
}

export default ValidationView;