import React, { useState } from 'react';
import { CreateDestinationRecord } from '../../../types/global';
interface ManualHeadersStepProps {
    destRecord: CreateDestinationRecord;
    setDestRecord: (destRecord: CreateDestinationRecord) => void;
}


const ManualHeadersStep: React.FC<ManualHeadersStepProps> = ({ destRecord, setDestRecord }) => {
    const [editingIndex, setEditingIndex] = useState<{ schemaIndex: number; headerIndex: number } | null>(null);
    const [newHeaderName, setNewHeaderName] = useState<string>('');

    const handleCheckboxChange = (schemaIndex: number, headerIndex: number, field: 'required' | 'unique') => {
        const updatedHeaders = destRecord.config.map((schema, sIndex) =>
            sIndex === schemaIndex ? schema.map((header, hIndex) =>
                hIndex === headerIndex ? { ...header, [field]: !header[field] } : header
            ) : schema
        );
        setDestRecord({ ...destRecord, config: updatedHeaders });
    };

    const handleNameDoubleClick = (schemaIndex: number, headerIndex: number, currentName: string) => {
        setEditingIndex({ schemaIndex, headerIndex });
        setNewHeaderName(currentName);
    };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewHeaderName(e.target.value);
    };

    const handleNameBlur = (schemaIndex: number, headerIndex: number) => {
        const updatedHeaders = destRecord.config.map((schema, sIndex) =>
            sIndex === schemaIndex ? schema.map((header, hIndex) =>
                hIndex === headerIndex ? { ...header, header: newHeaderName } : header
            ) : schema
        );
        setDestRecord({ ...destRecord, config: updatedHeaders });
        setEditingIndex(null);
    };

    return (
        <div>
            <h1>Headers</h1>
            {destRecord.config.map((schema, schemaIndex) => (
                <div key={schemaIndex} style={{ marginBottom: '20px' }}>
                    <h2>Schema {schemaIndex + 1}</h2>
                    {schema.map((header, headerIndex) => (
                        <div key={`${header.header}_${headerIndex}`} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                            <input
                                type="checkbox"
                                checked={header.required}
                                onChange={() => handleCheckboxChange(schemaIndex, headerIndex, 'required')}
                                style={{ marginRight: '5px' }}
                            />
                            <label style={{ marginRight: '15px' }}>Required</label>
                            <input
                                type="checkbox"
                                checked={header.unique}
                                onChange={() => handleCheckboxChange(schemaIndex, headerIndex, 'unique')}
                                style={{ marginRight: '5px' }}
                            />
                            <label style={{ marginRight: '15px' }}>Unique</label>
                            {editingIndex?.schemaIndex === schemaIndex && editingIndex?.headerIndex === headerIndex ? (
                                <input
                                    type="text"
                                    value={newHeaderName}
                                    onChange={handleNameChange}
                                    onBlur={() => handleNameBlur(schemaIndex, headerIndex)}
                                    autoFocus
                                    style={{ marginRight: '15px' }}
                                />
                            ) : (
                                <span
                                    onDoubleClick={() => handleNameDoubleClick(schemaIndex, headerIndex, header.header)}
                                    style={{ cursor: 'pointer', marginRight: '15px' }}
                                >
                                    {header.header}
                                </span>
                            )}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default ManualHeadersStep;
