import { RecordModel } from 'pocketbase';
import React, { useState } from 'react';
import { API_ROUTE } from '../../lib/env';
interface UploadStepProps {
  setFlowId: (flowId: string) => void;
  setIsNextDisabled: (isNextDisabled: boolean) => void;
}


const UploadStep = ({ setFlowId, setIsNextDisabled }: UploadStepProps) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [uploadProgress, setUploadProgress] = useState<{ [key: string]: number }>({});
  const [processProgress, setProcessProgress] = useState<{ [key: string]: number }>({});
  const [uploadStatus, setUploadStatus] = useState<{ [key: string]: string }>({});

  const url_params = new URLSearchParams(window.location.search);
  const destination_id = url_params.get('destination_id');
  const api_key = url_params.get('api_key');

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;

    const files = Array.from(event.target.files);
    setSelectedFiles(files);
  };

  const handleFileUpload = () => {
    if (selectedFiles.length === 0) return;

    const fileIds: string[] = [];

    selectedFiles.forEach((file) => {
      const formData = new FormData();
      formData.append('file', file);

      const xhr = new XMLHttpRequest();

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentCompleted = Math.round((event.loaded * 100) / event.total);
          setUploadProgress((prevProgress) => ({
            ...prevProgress,
            [file.name]: percentCompleted,
          }));
        }
      };

      xhr.onload = () => {
        setProcessProgress((prevProgress) => ({
          ...prevProgress,
          [file.name]: 100,
        }));
        if (xhr.status === 200) {
          const flowRecord: RecordModel = JSON.parse(xhr.responseText);
          setFlowId(flowRecord.id)
          setIsNextDisabled(false);

          setUploadStatus((prevStatus) => ({
            ...prevStatus,
            [file.name]: 'Upload successful!',
          }));
        } else {
          setUploadStatus((prevStatus) => ({
            ...prevStatus,
            [file.name]: 'Upload failed.',
          }));
          try {
            let response = JSON.parse(xhr.responseText);
            if (response.detail) {
              alert(response.detail);
            }
          } catch (error) {
            console.error(error);
          }
        }
      };

      xhr.onerror = (event) => {
        setUploadStatus((prevStatus) => ({
          ...prevStatus,
          [file.name]: 'Upload failed.',
        }));
        setUploadProgress((prevProgress) => ({
          ...prevProgress,
          [file.name]: 100,
        }));
        try {
          let response = JSON.parse(xhr.responseText);
          if (response.detail) {
            alert(response.detail);
          }
        } catch (error) {
          console.error(error);
        }
      };

      xhr.open('POST', `${API_ROUTE}/api/files/upload`, true);

      if (!api_key) {
        console.error("No API key provided");
        return;
      }

      if (!destination_id) {
        console.error("No destination ID provided");
        return;
      }
      xhr.setRequestHeader('enctype', 'multipart/form-data');
      xhr.setRequestHeader('x-api-key', api_key);
      xhr.setRequestHeader('destination_id', destination_id);

      setProcessProgress((prevProgress) => ({
        ...prevProgress,
        [file.name]: 0,
      }));

      xhr.send(formData);
    });
  };

  return (
    <div style={{ height: '100%' }}>
      <input type="file" multiple onChange={handleFileChange} />
      <button onClick={handleFileUpload}>Upload</button>
      {selectedFiles.length > 0 && (
        <div>
          {selectedFiles.map((file) => (
            <div key={file.name}>
              Upload progress - {uploadProgress[file.name] || 0}%<br />
              <div style={{ backgroundColor: '#f3f3f3', border: '1px solid #ccc' }}>
                <div
                  style={{
                    width: `${uploadProgress[file.name] || 0}%`,
                    height: '24px',
                    backgroundColor: (uploadProgress[file.name] || 0) === 100 ? '#4caf50' : '#2196f3',
                    transition: 'width 0.5s',
                  }}
                />
              </div>

              Process progress - {processProgress[file.name] || 0}%<br />
              <div style={{ backgroundColor: '#f3f3f3', border: '1px solid #ccc' }}>
                <div
                  style={{
                    width: `${processProgress[file.name] || 0}%`,
                    height: '24px',
                    backgroundColor: (processProgress[file.name] || 0) === 100 ? '#4C80AF' : '#2196f3',
                    transition: 'width 0.5s',
                  }}
                />
              </div>
              <p>{file.name}</p>
              <p>{uploadStatus[file.name] || ''}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default UploadStep;
