import React, { memo, useState } from 'react';
import { Handle, Position } from 'reactflow';
import { EleganNode } from '../Analysis';
import CustomHandle from './CustomHandle';


interface ConnectNodeProps {
    data: EleganNode["data"];
    isConnectable: boolean;
}

interface DateConvertionModalProps {
    data: EleganNode["data"];
    setDisplayModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const GetIndexModal = ({ data, setDisplayModal }: DateConvertionModalProps) => {
    // Assuming data.arguments is an array and might be undefined or have fewer elements
    const defaultIndex = data?.arguments?.[0] || "";
    const defaultUnmatched = data?.arguments?.[1] || "return_fail";

    if (typeof defaultIndex !== "string" || typeof defaultUnmatched !== "string") {
        console.error("Invalid arguments", data.arguments);
        throw new Error("Invalid arguments");
    }

    const [index, setIndex] = useState<string>(defaultIndex);
    const [unmatched, setUnmatched] = useState<string>(defaultUnmatched);
    return (
        <div style={{ overflowY: 'auto', backgroundColor: 'gray', color: 'white', padding: '10px' }}>
            <h1>Get Index</h1>
            <div className="flex">
                <div className='flex' style={{ flexDirection: 'column' }}>
                    <h3>Index</h3>
                    <input
                        className="main__input"
                        type="text"
                        name="input_format_date"
                        placeholder="%Y-%m-%d %H:%M:%S"
                        onChange={(e) => {
                            setIndex(e.target.value);
                        }}
                        defaultValue={data.arguments[0] as string}
                    />
                </div>
            </div>
            <h3>Unmatched conditions</h3>
            <select id="unmatched" onChange={(e) => {
                setUnmatched(e.target.value);
            }}
                defaultValue={unmatched}
            >
                <option value="return_fail">Fail row</option>
                <option value="return_empty">Continue with empty value</option>
                <option value="return_input">Forward the input value</option>
            </select>
            <br />
            <button
                onClick={() => {
                    if (!index || !unmatched) {
                        console.log(index, unmatched)
                        alert("Please fill all the fields");
                        return;
                    }

                    data.arguments = [
                        index,
                        unmatched,
                    ]
                    setDisplayModal(false);
                }}
            >
                Save
            </button>
        </div>
    );
};



const GetIndexNode = ({ data, isConnectable }: ConnectNodeProps) => {
    const [displayModal, setDisplayModal] = useState<boolean>(false);
    return (
        <>
            <CustomHandle
                type="target"
                position={Position.Left}
                style={{ background: '#555' }}
                isConnectable={data.inputNodeCount}

            />
            <div onDoubleClick={() => {
                console.log("Double click")
                setDisplayModal(true);
            }} className="node-container">
                <div className="node-bio">
                    {data.label}
                </div>

            </div>
            <CustomHandle type="source" position={Position.Right} style={{ background: '#555' }} isConnectable={data.outputNodeCount} />
            {displayModal && <GetIndexModal data={data} setDisplayModal={setDisplayModal} />}
        </>
    );
};

export default memo(GetIndexNode);