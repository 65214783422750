const NodeSelection = () => {

    return (
        <div className='functions'>
            <h2>Flow View</h2>
            <p className='muted'>Drag and drop the nodes to create a flow</p>

            <div className='card-group'>
                <h3>String Manipulation</h3>
                <div draggable onDragStart={(e) => e.dataTransfer.setData('application/reactflow', 'convert_date')} className='card'>convert_date</div>
                <div draggable onDragStart={(e) => e.dataTransfer.setData('application/reactflow', 'upper_case')} className='card'>upper_case</div>
                <div draggable onDragStart={(e) => e.dataTransfer.setData('application/reactflow', 'lower_case')} className='card'>lower_case</div>
                <div draggable onDragStart={(e) => e.dataTransfer.setData('application/reactflow', 'count')} className='card'>count</div>
                <div draggable onDragStart={(e) => e.dataTransfer.setData('application/reactflow', 'name_parser')} className='card'>name_parser</div>
                <div draggable onDragStart={(e) => e.dataTransfer.setData('application/reactflow', 'split_on_delimiter')} className='card'>split_on_delimiter</div>
                <div draggable onDragStart={(e) => e.dataTransfer.setData('application/reactflow', 'strip')} className='card'>strip</div>
            </div>

            <div className='card-group'>
                <h3>URL Operations</h3>
                <div draggable onDragStart={(e) => e.dataTransfer.setData('application/reactflow', 'encode_url')} className='card'>encode_url</div>
                <div draggable onDragStart={(e) => e.dataTransfer.setData('application/reactflow', 'decode_url')} className='card'>decode_url</div>
            </div>

            <div className='card-group'>
                <h3>Other</h3>
                <div draggable onDragStart={(e) => e.dataTransfer.setData('application/reactflow', 'replace')} className='card'>replace</div>
                <div draggable onDragStart={(e) => e.dataTransfer.setData('application/reactflow', 'sprintf')} className='card'>sprintf</div>
                <div draggable onDragStart={(e) => e.dataTransfer.setData('application/reactflow', 'get_index')} className='card'>get_index</div>
                <div draggable onDragStart={(e) => e.dataTransfer.setData('application/reactflow', 'switch_case')} className='card'>switch_case</div>
            </div>
        </div>
    );
}

export default NodeSelection;