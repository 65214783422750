// src/pages/Home.tsx
import React, { useEffect, useState } from 'react';
import FrameModel from '../components/FrameModal';
import pb from '../lib/pocketbase';
import { DestinationRecord } from '../../types/global';
import { WEB_HOST, WEB_PORT } from '../lib/env';
// import './Home.css'; // Import the CSS file for styling

const Home: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [destinations, setDestinations] = useState<DestinationRecord[]>([]);
  const [code, setCode] = useState<string>('');

  useEffect(() => {
    pb.collection("destinations").getFullList<DestinationRecord>({ sort: '-created' })
      .then((data) => {
        setDestinations(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);


  return (
    <>
      <h1>Home Page</h1>

      {destinations.map((destination) => (
        <div key={destination.id}>
          <h2>{destination.name}</h2>
          <p>{destination.description}</p>

          <button onClick={() => {
            setCode(`<iframe style="margin: 0;padding: 0;height: 100vh;" src="${WEB_HOST}:${WEB_PORT}/flow?destination_id=${destination.id}&api_key=${destination.token_id}"></iframe>`);
            setIsModalOpen(true);
          }}>Show Code</button>
        </div>
      ))}
      {isModalOpen && (
        <FrameModel setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} code={code} />
      )}

    </>
  );
};

export default Home;
