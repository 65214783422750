import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { toast } from 'react-toastify';
import { vs2015 } from 'react-syntax-highlighter/dist/cjs/styles/hljs';
import './FrameModal.css';
import { ToastDisplay } from './Toast';
interface FrameModalProps {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isModalOpen: boolean;
  code: string;
}



const FrameModal: React.FC<FrameModalProps> = ({ setIsModalOpen, isModalOpen, code }) => {
  const [copied, setCopied] = useState(false);


  const notify = () => {
    toast(<ToastDisplay />);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 5000);
  };

  return (
    <div className="modal">
      <div style={{ marginLeft: '10px', marginRight: '10px', marginTop: '5px', justifyContent: 'space-between' }} className="flex">
        <CopyToClipboard text={code} onCopy={() => notify()}>
          {copied ? (
            <img className="iframe-icon" src="ion-circle.svg" alt="My Happy SVG" />
          ) : (
            <img className="iframe-icon" src="ion-copy.svg" alt="My Happy SVG" />
          )}
        </CopyToClipboard>


        <button
          onClick={() => setIsModalOpen(!isModalOpen)}
          style={{
            border: 'none',
            cursor: 'pointer',
            color: '#e74c3c',
            fontSize: '20px',
          }}
        >
          &#x2715;
        </button>
      </div>
      <SyntaxHighlighter
        className="modal-codeblock"
        language={"html"}
        style={vs2015}
        wrapLines={true}
        wrapLongLines={true}
        showLineNumbers={false}
        showInlineLineNumbers={false}
      >
        {code}
      </SyntaxHighlighter>
    </div>
  );
};

export default FrameModal;