import React, { useEffect, useState } from 'react';
import pb from '../../lib/pocketbase';
import { DestinationRecord, DestinationConfig, FileRecord, FlowRecord } from '../../../types/global';
import './ManualHeaders.css'
import { API_ROUTE } from '../../lib/env';
import { set } from 'react-hook-form';

interface ManualHeadersStepProps {
  flowId: string;
  destRecord: DestinationRecord;
  apiKey: string;
  setIsNextDisabled: (isDisabled: boolean) => void;
}

interface Prelim {
  flow_id: string;
  destination_id: string;
  metadata: FileMetadata[];
}

interface FileMetadata {
  file_id: string;
  headers: Record<string, any>;  // Changed to Record<string, any> to match the new structure
}

interface LoadingProps {
  type: string;
}


const ManualHeadersStep: React.FC<ManualHeadersStepProps> = ({ flowId, destRecord, apiKey, setIsNextDisabled }) => {
  //initial user file headers
  const [userInputHeaders, setUserInputHeaders] = useState<string[][]>([]);
  const [submitSuccessful, setSubmitSuccessful] = useState(false);
  const [flowData, setFlowData] = useState<FlowRecord | null>(null);
  const [isHeaderLoading, setIsHeaderLoading] = useState(false);
  const [isFunctionLoading, setIsFunctionLoading] = useState(false);
  // array of objects with key being the target header and the value being the user header
const [headerMapping, setHeaderMapping] = useState<Array<{ [key: string]: string | string[] | null }>>([]);

const [errors, setErrors] = useState<string[]>([]);

useEffect(() => {
  console.log(headerMapping);
} , [headerMapping]);

useEffect(() => {
  console.log("destRecord.config");
  console.log(destRecord.config);
} , [destRecord]);
// This sets headerMapping to the schema headers, and their value to null
useEffect(() => {
  if (destRecord.config && destRecord.config[0] && Array.isArray(destRecord.config[0])) {
    const initialHeaderMapping = destRecord.config[0].map(item => ({
      [item.header]: null
    }));
    setHeaderMapping(initialHeaderMapping);
    console.log('initialHeaderMapping:', initialHeaderMapping)
  }
}, []);

useEffect(() => {
  console.log('headerMapping:', headerMapping);

} , [headerMapping]);

  useEffect(() => {
    const requiredErrors = destRecord.config[0]
      .filter(item => item.required)
      .map(item => item.header)
      .filter(header => 
        headerMapping.find(obj => Object.keys(obj)[0] === header)?.[header] === null
      );

    setErrors(requiredErrors);
  }, [headerMapping, destRecord.config]);

  useEffect(() => {
    setIsNextDisabled(!submitSuccessful);
  }, [submitSuccessful, setIsNextDisabled]);

  useEffect(() => {
    const fetchFlowData = async () => {
      try {
        const flow = await pb.collection("flows").getOne<FlowRecord>(flowId);
        setFlowData(flow);

        const filePromises = flow.files.map(fileId => pb.collection('files').getOne<FileRecord>(fileId));
        const files = await Promise.all(filePromises);

        setUserInputHeaders(files.map(file => file.inputs.map(input => input.header)));
      } catch (err) {
        console.error(err);
      }
    };

    fetchFlowData();
  }, [flowId]);


const Loading: React.FC<LoadingProps> = ({ type }) => (
  <div className="loading-overlay">
    <div className="loading-content">
      <p>{type}...</p>
      <div className="spinner"></div>
    </div>
  </div>
);




const handleSelectChange = (header: string, value: string, index?: number) => {
  setHeaderMapping(prevMapping => 
    prevMapping.map(headerObj => {
      if (Object.keys(headerObj)[0] === header) {
        if (Array.isArray(headerObj[header])) {
          const newValue = [...headerObj[header] as string[]];
          if (index !== undefined) {
            newValue[index] = value;
          }
          return { [header]: newValue.length > 1 ? newValue : newValue[0] };
        } else {
          return { [header]: value };
        }
      }
      return headerObj;
    })
  );
};

const handleAddMapping = (header: string) => {
  setHeaderMapping(prevMapping =>
    prevMapping.map(headerObj => {
      if (Object.keys(headerObj)[0] === header) {
        const currentValue = headerObj[header];
        if (Array.isArray(currentValue)) {
          return { [header]: [...currentValue, ''] };
        } else if (currentValue === null) {
          return { [header]: ['', ''] }; // Create array with two empty strings
        } else {
          return { [header]: [currentValue, ''] };
        }
      }
      return headerObj;
    })
  );
};

const handleRemoveMapping = (header: string, index?: number) => {
  setHeaderMapping(prevMapping =>
    prevMapping.map(headerObj => {
      if (Object.keys(headerObj)[0] === header) {
        let newValue = headerObj[header];
        if (Array.isArray(newValue) && index !== undefined) {
          newValue = newValue.filter((_, i) => i !== index);
          if (newValue.length === 1) {
            return { [header]: newValue[0] || null };  // Convert single-element array to single value or null
          } else if (newValue.length === 0) {
            return { [header]: null };  // If array becomes empty, set to null
          } else {
            return { [header]: newValue };  // Keep as array if multiple elements remain
          }
        } else {
          return { [header]: null };  // For non-array values, set to null when removed
        }
      }
      return headerObj;
    })
  );
};

const renderSelectBoxes = (header: string, value: string | string[] | null) => {
  const renderSingleSelect = (currentValue: string | null, index?: number) => (
    <div key={`${header}-${index ?? 0}`} style={{ display: 'flex', marginBottom: '5px' }}>
      <select 
        value={currentValue || ""}
        onChange={(e) => handleSelectChange(header, e.target.value, index)}
      >
        <option value="">Select header</option>
        {userInputHeaders.flat().map((userHeader, idx) => (
          <option key={idx} value={userHeader}>
            {userHeader}
          </option>
        ))}
      </select>
      {(Array.isArray(value) && value.length > 1) || (value !== null && index === undefined) ? (
        <button onClick={() => handleRemoveMapping(header, index)}>Remove</button>
      ) : null}
    </div>
  );

  let selectBoxes;
  if (Array.isArray(value)) {
    selectBoxes = value.map((item, index) => renderSingleSelect(item, index));
  } else {
    selectBoxes = renderSingleSelect(value);
  }

return (
  <>
    {selectBoxes}
    <button onClick={() => handleAddMapping(header)}>Add Mapping</button>
  </>
);
};




 const handleGetMapping = () => {
    if (!flowData) return;

    const userHeaders = flowData.files.flatMap(fileId => 
      userInputHeaders[flowData.files.indexOf(fileId)] || []
    ).filter((header): header is string => typeof header === 'string');

    const targetHeaders = destRecord.config.flatMap(configArray => 
      configArray.map(config => config.header)
    );

    handleHeaderMapping(userHeaders, targetHeaders);
  };

  
  const handleHeaderMapping = async (userHeaders: string[], targetHeaders: string[]) => {

  if (!flowData) return;

  const metadata = flowData.files.map(fileId => ({
    file_id: fileId
    }));
  setIsHeaderLoading(true);
  try {
    const response = await fetch(`${API_ROUTE}/api/transform/header-mapping`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-API-Key': apiKey,
      },
      body: JSON.stringify({
        user_headers: userHeaders,
        target_headers: targetHeaders,
        flow_id: flowId,
        destination_id: destRecord.id,
        metadata: metadata,
  }),
      });

    if (!response.ok) {
      throw new Error('Failed to get header mapping');
    }

    const data = await response.json();
    console.log('Received headers:', data);
    
    if (data.mapping) {
      setHeaderMapping(prevMapping => 
        prevMapping.map(headerObj => {
          const [header] = Object.keys(headerObj);
          return {
            [header]: data.mapping[header] !== undefined ? data.mapping[header] : headerObj[header]
          };
        })
      );
    }
  } catch (error) {
    console.error('Error:', error);
  } finally {
    setIsHeaderLoading(false);
  
  }
};



const handleSubmit = () => {
  if (!flowData) return;

  // Check for required fields
  const requiredErrors = destRecord.config[0]
    .filter(item => item.required)
    .map(item => item.header)
    .filter(header => 
      headerMapping.find(obj => Object.keys(obj)[0] === header)?.[header] === null
    );

  setErrors(requiredErrors);

  if (requiredErrors.length > 0) {
    console.error('Required fields are missing:', requiredErrors);
    return;
  }
  setIsFunctionLoading(true);

  const metadata = flowData.files.map(fileId => ({
    file_id: fileId,
    headers: headerMapping
  }));

  console.log('Submitting metadata:', metadata);

  fetch(`${API_ROUTE}/api/transform/prelim`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-API-Key': apiKey,
    },
    body: JSON.stringify({
      flow_id: flowId,
      destination_id: destRecord.id,
      metadata: metadata,
    } as Prelim),
  })
    .then((res) => {
      if (res.ok) {
        setSubmitSuccessful(true);
      }
      return res.json();
    })
    .then((data) => {
      console.log(data);
    })
    .catch((error) => {
      console.error('Error:', error);
    })
    .finally(() => {
      setIsFunctionLoading(false);
    });
};


  return (
    <>
    {isHeaderLoading && <Loading type="Auto matching headers" />}
    {isFunctionLoading && <Loading type="Migrating to new format" />}
      <h1>Manual Headers</h1>
      <p>Map the headers from the files to the destination</p>
      <button onClick={handleGetMapping}>Get Mapping</button>
    <div className="header-container">
     {headerMapping.map((headerObj, index) => {
  const [header, value] = Object.entries(headerObj)[0];
  return (
    <div key={index} className="header-row">
      <h3>{header}</h3>
      {renderSelectBoxes(header, value)}
    </div>
  );
})}
    </div>
      <button onClick={handleSubmit}>Submit</button>
   <div>
  <h3>Errors</h3>
  {errors.length > 0 && (
    <ul>
      {errors.map((error, index) => (
        <li key={index}>Required field missing: {error}</li>
      ))}
    </ul>
  )}
</div>

    </>
  );
};

export default ManualHeadersStep;