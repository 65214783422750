import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';
import CustomHandle from './CustomHandle';
import { EleganNodeData } from '../Analysis';

interface OutputNodeProps {
    data: EleganNodeData;
}


const OutputNode = ({ data }: OutputNodeProps) => {
    return (
        <>
            <CustomHandle
                type="target"
                position={Position.Left}
                style={{ background: '#555' }}
                isConnectable={data.inputNodeCount}
            />
            <div className="node-container">
                <div className="node-container-before-primary">Output</div>
                <div style={{ display: 'flex' }} className="node-bio">
                    <div>{data.label}</div>
                </div>
            </div>
        </>
    );
};

export default memo(OutputNode);