import SwaggerUI from 'swagger-ui-react';
import { API_ROUTE } from '../lib/env';

import './Docs.css';

const Docs = () => {
  return (
    <SwaggerUI url={`${API_ROUTE}/api/openapi.json`} />
  );
};

export default Docs;