import { useMutation } from 'react-query';
import pb from '../lib/pocketbase';
export interface FormData {
  email: string;
  password: string;
}


export default function useLogin() {
  async function login({ email, password }: FormData): Promise<void> {
    await pb.collection('users').authWithPassword(email, password);
  }
  return useMutation(login);
}
