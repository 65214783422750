import React, { useCallback, useEffect, useState, DragEvent } from 'react';
import ReactFlow, {
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  getIncomers,
  getOutgoers,
  getConnectedEdges,
  Connection,
  Edge,
  Node,
  ReactFlowProvider,
  applyEdgeChanges,
  applyNodeChanges,
  NodeChange,
  EdgeChange,
  ReactFlowInstance,
  Position,
} from 'reactflow';
import './FlowView.css'
import 'reactflow/dist/style.css';
import { EleganNode, NodesAndEdges } from './Analysis';
import { DestinationConfig, DestinationRecord, FileRecord, FlowRecord, Operation } from '../../../../types/global';
import NodeSelection from './NodesSidebar';
import InputNode from './Nodes/InputNode';
import OutputNode from './Nodes/OutputNode';
import ConvertDateNode from './Nodes/ConvertDateNode';
import UpperCaseNode from './Nodes/UpperCaseNode';
import LowerCaseNode from './Nodes/LowerCaseNode';
import CountNode from './Nodes/CountNode';
import SplitOnDelimiterNode from './Nodes/SplitOnDelimiterNode';
import StripNode from './Nodes/StripNode';
import EncodeUrlNode from './Nodes/EncodeUrlNode';
import DecodeUrlNode from './Nodes/DecodeUrlNode';
import ReplaceNode from './Nodes/ReplaceNode';
import SprintfNode from './Nodes/SprintfNode';
import GetIndexNode from './Nodes/GetIndexNode';
import SwitchCaseNode from './Nodes/SwitchCaseNode';
import { Slide, toast, } from 'react-toastify';
interface FlowViewProps {
  fileMetadata: FileRecord[] | null;
  flowMetadata: FlowRecord | null;
  destRecord: DestinationRecord | null;
  nodes: EleganNode[];
  setNodes: React.Dispatch<React.SetStateAction<EleganNode[]>>;
  edges: Edge[];
  setEdges: React.Dispatch<React.SetStateAction<Edge[]>>;
  reactFlowInstance: ReactFlowInstance | null;
  setReactFlowInstance: React.Dispatch<React.SetStateAction<ReactFlowInstance | null>>;
  modifications: NodesAndEdges[];
  setModifications: React.Dispatch<React.SetStateAction<NodesAndEdges[]>>;
  rendered: boolean;
  setRendered: React.Dispatch<React.SetStateAction<boolean>>;
  getId: () => string;
  errors: string[];
  setErrors: React.Dispatch<React.SetStateAction<string[]>>;
}




interface FunctionDefinition {
  function: string;
  inputNodeCount: number;
  outputNodeCount: number;
  inputType: string;
  outputType: string;
}

interface FunctionsMap {
  [key: string]: FunctionDefinition;
}

const functions: FunctionsMap = {
  "convert_date": { function: 'convert_date', inputNodeCount: 1, outputNodeCount: 1, inputType: 'string', outputType: 'string' },
  "upper_case": { function: 'upper_case', inputNodeCount: 1, outputNodeCount: 1, inputType: 'string', outputType: 'string' },
  "lower_case": { function: 'lower_case', inputNodeCount: 1, outputNodeCount: 1, inputType: 'string', outputType: 'string' },
  "count": { function: 'count', inputNodeCount: 1, outputNodeCount: 1, inputType: 'list', outputType: 'string' },
  "split_on_delimiter": { function: 'split_on_delimiter', inputNodeCount: 1, outputNodeCount: Infinity, inputType: 'string', outputType: 'list' },
  "strip": { function: 'strip', inputNodeCount: 1, outputNodeCount: 1, inputType: 'string', outputType: 'string' },
  "encode_url": { function: 'encode_url', inputNodeCount: 1, outputNodeCount: 1, inputType: 'string', outputType: 'string' },
  "decode_url": { function: 'decode_url', inputNodeCount: 1, outputNodeCount: 1, inputType: 'string', outputType: 'string' },
  "replace": { function: 'replace', inputNodeCount: 1, outputNodeCount: 1, inputType: 'string', outputType: 'string' },
  "sprintf": { function: 'sprintf', inputNodeCount: Infinity, outputNodeCount: 1, inputType: 'string', outputType: 'string' },
  "get_index": { function: 'get_index', inputNodeCount: 1, outputNodeCount: 1, inputType: 'list', outputType: 'string' },
  "switch_case": { function: 'switch_case', inputNodeCount: 1, outputNodeCount: 1, inputType: 'list', outputType: 'string' },
}

const nodeTypes = {
  Input: InputNode,
  Output: OutputNode,
  convert_date: ConvertDateNode,
  upper_case: UpperCaseNode,
  lower_case: LowerCaseNode,
  count: CountNode,
  split_on_delimiter: SplitOnDelimiterNode,
  strip: StripNode,
  encode_url: EncodeUrlNode,
  decode_url: DecodeUrlNode,
  replace: ReplaceNode,
  sprintf: SprintfNode,
  get_index: GetIndexNode,
  switch_case: SwitchCaseNode,
};

// Function to check if schemaHeader exists in the schema list
function isHeaderInSchema(schema: DestinationConfig[], schemaHeader: string): boolean {
  return schema.some(config => config.header === schemaHeader);
}

export const render = (destRecord: DestinationRecord, flowMetadata: FlowRecord, fileMetadata: FileRecord[], getId: () => string, setEdges: React.Dispatch<React.SetStateAction<Edge[]>>, setNodes: React.Dispatch<React.SetStateAction<EleganNode[]>>) => {

  const inputNodes = fileMetadata.map((file, fileIndex) => {
    return file.inputs.map((input, index) => ({
      id: getId(),
      type: 'Input',

      data: {
        label: input.header,
        index: index,
        inputNodeCount: 0,
        outputNodeCount: Infinity,
        inputType: null,
        outputType: 'string'
      }, // Assuming input.header is already a string
      position: { x: 0, y: (index * 100) + (fileIndex * 100) },
      sourcePosition: 'left',
      draggable: true,
    } as EleganNode));
  });
  setNodes(inputNodes.flat());

  // Iterate the schemas in the client schemas
  for (let schemaIndex = 0; schemaIndex < destRecord.config.length; schemaIndex++) {
    const schema = destRecord.config[schemaIndex];

    // Check if all schema headers are found in the LLM output in the files collection
    for (let headerIndex = 0; headerIndex < schema.length; headerIndex++) {
      const header = schema[headerIndex].header;
      if (!flowMetadata.mapping[schemaIndex].hasOwnProperty(header)) {
        alert(`Header ${header} not found in schema`);
        return;
      }
    }

    // Foreach header in the schema
    const fileHeaders = Object.entries(flowMetadata.mapping[schemaIndex]);
    for (let schemaHeaderIndex = 0; schemaHeaderIndex < fileHeaders.length; schemaHeaderIndex++) {
      const schemaHeader = fileHeaders[schemaHeaderIndex][0];
      const headerRequired = schema.find(config => config.header === schemaHeader)?.required;
      const schemaObject: string | null | number | Operation = fileHeaders[schemaHeaderIndex][1];


      // Check if LLM hallucinated header existing in schema
      if (!isHeaderInSchema(schema, schemaHeader)) {
        console.warn(`Output node key ${schemaHeader} not found in schema ${schemaIndex}`);
        continue;
      }

      const nodeHeight = 50;
      const y = schemaHeaderIndex * nodeHeight;
      var depth = 300;

      const apply = (operations: string | number | null | Operation, depth: number, inputNodes: EleganNode[][], schemaIndex: number): EleganNode | null | string | number => {
        if (operations === null) return null; // Schema didn't have any mapping

        if (typeof operations === 'string') {
          if (!operations.startsWith('__label__')) {
            return operations;
          }
          const inputHeaderSplit = operations.split('__label__', 2);
          if (inputHeaderSplit.length === 1) {
            throw new Error(`Response was malformed, input header ${operations} couldn't be split on __label__`);
          }

          let inputNodeMatches = inputNodes.flat().filter((node) => node.data.label === inputHeaderSplit[1]);
          if (inputNodeMatches.length === 0) {
            console.warn(`Input node with label ${inputHeaderSplit[1]} not found`);
            return null;
          }

          if (inputNodeMatches.length > 1) {
            console.warn(`Multiple input nodes with label ${inputHeaderSplit[1]} found`);
            return null;
          }

          return inputNodeMatches[0];
        }

        if (typeof operations === 'number') {
          return operations;
        }

        if (typeof operations === 'object') {
          if (!functions[operations.operation]) {
            console.warn(`Function ${operations.operation} not found in functions`);
            return null;
          }

          let newNode: EleganNode = {
            id: getId(),
            type: functions[operations.operation].function,
            position: { x: depth, y: y },

            data: {
              label: operations.operation,
              inputNodeCount: functions[operations.operation].inputNodeCount,
              outputNodeCount: functions[operations.operation].outputNodeCount,
              inputType: functions[operations.operation].inputType,
              outputType: functions[operations.operation].outputType,
              arguments: [],
              index: null,
              schemaIndex: schemaIndex,
              required: false,
            },
            sourcePosition: Position.Right,
          };

          // iterate args
          if (operations.arguments && Array.isArray(operations.arguments)) {
            operations.arguments.forEach((argument: string | number | Operation) => {
              let previousNode = apply(argument, depth - 300, inputNodes, schemaIndex);
              if (previousNode === null) return;

              if (typeof previousNode === 'string' || typeof previousNode === 'number') {
                newNode.data.arguments.push(previousNode);
                return;
              }

              let edge = { id: `${previousNode.id}->${newNode.id}`, source: previousNode.id, target: newNode.id };
              setEdges((eds) => eds.concat(edge));
            });
          }

          setNodes((nds) => nds.concat(newNode));
          return newNode;
        }

        throw new Error(`Unknown type ${typeof operations}`);

      }

      let connectingNode = apply(schemaObject, depth, inputNodes, schemaIndex);
      let outputNode = {
        id: getId(),
        type: 'Output',
        position: { x: depth + 300, y: y },

        data: {
          label: schemaHeader,
          index: schemaHeaderIndex,
          schemaIndex: schemaIndex,
          arguments: [],
          required: headerRequired,
          inputNodeCount: 1,
          outputNodeCount: 0,
          inputType: 'string',
          outputType: null,
        },
        sourcePosition: 'left' as Position,
        draggable: false,
      } as EleganNode;

      setNodes((nds) => nds.concat(outputNode));

      if (connectingNode === null) {
        if (headerRequired) {
          console.error(`No connecting node found for schema ${schemaIndex} header ${schemaHeader}`);
        } else {
          console.warn(`No connecting node found for schema ${schemaIndex} header ${schemaHeader}`);

        }
        continue;
      }

      if (typeof connectingNode === 'string' || typeof connectingNode === 'number') {
        throw new Error('Connecting node is a string or number, there shouldn\'t be any arguments on a output node');
      }

      if (!connectingNode.id) {
        throw new Error('Connecting node has no id');
      }

      const edge = { id: `${connectingNode.id}->${outputNode.id}`, source: connectingNode.id, target: outputNode.id };
      setEdges((eds) => eds.concat(edge));
    }
  }
}

export default function FlowView({ fileMetadata, flowMetadata, destRecord, nodes, setNodes, edges, setEdges, reactFlowInstance, setReactFlowInstance, modifications, setModifications, rendered, setRendered, getId, errors, setErrors }: FlowViewProps) {
  useEffect(() => {

    if (rendered || !fileMetadata || !destRecord || !flowMetadata) return;

    // Check that all files have inputs
    if (fileMetadata.some(file => file.inputs.length === 0)) {
      alert('Some files have no inputs');
      return;
    }
    render(destRecord, flowMetadata, fileMetadata, getId, setEdges, setNodes);
    setRendered(true);
  }, [rendered, fileMetadata, destRecord, flowMetadata]);

  // Undo button listener
  useEffect(() => {
    const handleKeyDown = (event: { ctrlKey: any; key: string; }) => {
      if (event.ctrlKey && event.key === 'z' && modifications.length > 0) {
        const index = modifications.length - 1;
        setNodes([...modifications[index].nodes]);
        setEdges([...modifications[index].edges]);
        setModifications(modifications.slice(0, index));
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [modifications, setNodes, setEdges, setModifications]);

  const onDrop = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault();

      // the type should be a enum value
      const typeId = event.dataTransfer.getData('application/reactflow');

      // check if the dropped element is valid
      if (typeId === undefined || typeId === null || typeId === 'undefined') {
        return;
      }

      if (!functions[typeId]) {
        console.error(`Function ${typeId} not found in functions`);
        return;
      }

      const node_details = functions[typeId];
      const newNode = {
        id: getId(),
        type: typeId,
        position: { x: event.clientX, y: event.clientY },

        data: {
          label: node_details.function,
          arguments: [],
          index: null,
          schemaIndex: null,
          required: false,
          inputNodeCount: node_details.inputNodeCount,
          outputNodeCount: node_details.outputNodeCount,
          inputType: node_details.inputType,
          outputType: node_details.outputType,
        },
        sourcePosition: Position.Right,
      };

      setNodes((prevNodes) => [...prevNodes, newNode]);

    },
    [functions, setNodes]
  );


  const onNodesChange = useCallback((changes: NodeChange[]) => {
    setNodes((nds: EleganNode[]): EleganNode[] => {
      const filteredChanges = changes.filter(change => {
        if (change.type === 'remove') {
          const nodeToRemove = nds.find(node => node.id === change.id);
          return !(nodeToRemove?.type === 'Input' || nodeToRemove?.type === 'Output');
        }
        return true;
      });
      return applyNodeChanges(filteredChanges, nds);
    });
  }, [setNodes]);
  const onEdgesChange = useCallback((changes: EdgeChange[]) => setEdges((eds: Edge[]) => applyEdgeChanges(changes, eds)), [setEdges]);
  const onConnect = useCallback((connection: Connection) => {
    const sourceNode = nodes.find(node => node.id === connection.source);
    const targetNode = nodes.find(node => node.id === connection.target);

    if (sourceNode && targetNode && sourceNode.data.outputType === targetNode.data.inputType) {
      setEdges((eds) => addEdge(connection, eds));
    } else {
      toast.warn("Cannot connect nodes with different data types");
    }
  }, [nodes, setEdges]);
  const onNodeDragStop = useCallback((event: any, node: EleganNode) => {
    setModifications([...modifications, { nodes, edges }]);
  }, [setModifications, modifications]);

  const onNodesDelete = useCallback(
    (deleted: Node[]) => {

      // Save the current state
      setModifications([...modifications, { nodes: nodes, edges }]);

      setEdges(
        deleted.reduce((acc, node: Node) => {
          const incomers = getIncomers(node, nodes, edges);
          const outgoers = getOutgoers(node, nodes, edges);
          const connectedEdges = getConnectedEdges([node], edges);

          // Don't auto connect if there are more or less than one connection
          if (incomers.length !== 1) {
            toast.warn("Cannot auto connect nodes with multiple inputs");
            return acc.filter((edge) => !connectedEdges.includes(edge));
          }

          // Don't auto connect if the input and output types are different
          if (outgoers[0].data.inputType !== incomers[0].data.outputType) {
            toast.warn("Cannot connect nodes with different data types");
            return acc.filter((edge) => !connectedEdges.includes(edge));
          }

          const remainingEdges = acc.filter((edge) => !connectedEdges.includes(edge));

          const createdEdges = incomers.flatMap(({ id: source }) =>
            outgoers.map(({ id: target }) => ({ id: `${source}->${target}`, source, target }))
          );

          return [...remainingEdges, ...createdEdges];
        }, edges)
      );
    },
    [nodes, edges]
  );

  if (!destRecord || !fileMetadata) {
    return <div>Loading...</div>;
  }

  const styles = {
    width: '80%',
    height: 300,
  };

  return (
    <div className='flow-container'>
      <NodeSelection />
      <ReactFlowProvider>

        <ReactFlow
          style={styles}
          nodeTypes={nodeTypes}
          nodes={nodes}
          edges={edges}
          onDragOver={(event) => {
            event.preventDefault();
            event.dataTransfer.dropEffect = 'move';
          }}
          onDrop={onDrop}
          onNodesChange={onNodesChange}
          onNodesDelete={onNodesDelete}
          onEdgesChange={onEdgesChange}
          onNodeDragStop={onNodeDragStop}
          onConnect={onConnect}
          onInit={setReactFlowInstance}
          fitView
          attributionPosition="top-right"
        >
        </ReactFlow>
      </ReactFlowProvider>
      <div className="error-container">
        <div className="error-list">
          <h2>Errors</h2>
          {errors.length > 0 ? (
            errors.map((error, index) => (
              <p key={index}>{error}</p>
            ))
          ) : (
            <p>No errors</p>
          )}
        </div>
      </div>
    </div>

  );
}