import React, { memo, useEffect, useRef, useState } from 'react';
import { Handle, Position, useNodes, useNodeId, getOutgoers, useEdges } from 'reactflow';
import { EleganNode, EleganNodeData } from '../Analysis';
import CustomHandle from './CustomHandle';

interface ConnectNodeProps {
    data: EleganNodeData;
}

interface SwitchCaseModalProps {
    data: EleganNodeData;
    setDisplayModal: React.Dispatch<React.SetStateAction<boolean>>;
    outgoers: EleganNode[];
}

const SwitchCaseModal = ({ data, setDisplayModal, outgoers }: SwitchCaseModalProps) => {
    // If the length of arguments don't match the outgoers create the same number of arguments as outgoers
    let defaultCases = data.arguments as string[];
    const [cases, setCases] = useState<string[]>(defaultCases);

    useEffect(() => {
        if (cases.length !== outgoers.length) {
            const newCases = new Array(outgoers.length).fill("");
            setCases(newCases);
        }
    }, [outgoers.length]);

    return (
        <div style={{ overflowY: 'auto', backgroundColor: 'gray', color: 'white', padding: '10px' }}>
            <h1>Switch Case</h1>
            <div className="flex" style={{ justifyContent: 'center' }}>
                <div className='flex' style={{ flexDirection: 'column' }}>
                    <h3>Cases</h3>
                    {cases.map((caseValue, index) => {
                        return (
                            <input
                                className="main__input"
                                style={{ padding: '10px', marginBottom: '10px' }}
                                type="text"
                                placeholder="Case value"
                                onChange={(e) => {
                                    cases[index] = e.target.value;
                                    setCases([...cases]);
                                }}
                                defaultValue={caseValue}
                            />
                        )
                    })}
                </div>
                <div className='flex' style={{ flexDirection: 'column' }}>
                    <h3>Outputs</h3>
                    {outgoers.map((outgoer, index) => {
                        return (
                            <div className="card" style={{ color: 'black' }}>
                                {outgoer.data.label}
                            </div>
                        );
                    })}
                </div>
            </div>
            <h3>Unmatched conditions</h3>
            <br />
            <button
                onClick={() => {
                    setDisplayModal(false);
                }}
            >
                Save
            </button>
        </div>
    );
};



const SwitchCaseNode = ({ data }: ConnectNodeProps) => {
    const nodeId = useNodeId();
    const nodes = useNodes();
    const edges = useEdges();

    const node = nodes.find((node) => node.id === nodeId);
    if (!node) {
        throw new Error("Node not found");
    }

    const outgoers = getOutgoers(node, nodes, edges) as EleganNode[];
    const [displayModal, setDisplayModal] = useState<boolean>(false);
    return (
        <>
            <CustomHandle
                type="target"
                position={Position.Left}
                style={{ background: '#555' }}
                isConnectable={data.inputNodeCount}

            />
            <div onDoubleClick={() => {
                console.log("Double click")
                setDisplayModal(true);
            }} className="node-container">
                <div className="node-bio">
                    {data.label}
                </div>

            </div>
            <CustomHandle type="source" position={Position.Right} style={{ background: '#555' }} isConnectable={data.outputNodeCount} />
            {displayModal && <SwitchCaseModal data={data} setDisplayModal={setDisplayModal} outgoers={outgoers} />}
        </>
    );
};

export default memo(SwitchCaseNode);