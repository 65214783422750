import { useEffect, useState } from "react";
import pb from "../lib/pocketbase";
import { API_ROUTE } from "../lib/env";

interface File {
    id: string;
    title: string;
    size: number;
    modified: string;
    location: string;
    folder: string;
    metadata: string;
    pocket_id: string | null;
}

const Files = () => {
    const [files, setFiles] = useState<File[]>([]);

    useEffect(() => {
        fetch(`${API_ROUTE}/api/files/list`, {
            headers: {
                Authorization: `Bearer ${pb.authStore.token}`,
            },
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Failed to fetch files');
            }
            return response.json();
        })
        .then(data => setFiles(data))
        .catch(error => console.error(error));
    }, []);

    const deleteFile = (title: string) => {
        fetch(`${API_ROUTE}/api/file?path=${title}`, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${pb.authStore.token}`,
            },
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Failed to delete file');
            }
            // Update the state to remove the deleted file
            setFiles(files.filter(file => file.title !== title));
        })
        .catch(error => console.error(error));
    };

    return (
      <>
          <h1>Files</h1>
          <ul>
              {files.map((file: File, index: number) => (
                  <li key={`${file.id}-${index}`}>
                      <a href={`${API_ROUTE}/api/file/download?path=${file.title}&authkey=${pb.authStore.token}`}>{file.title} </a>
                      <button onClick={() => deleteFile(file.title)}>Delete</button>
                  </li>
              ))}
          </ul>
      </>
  );
};

export default Files;