import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';
import CustomHandle from './CustomHandle';
import { EleganNodeData } from '../Analysis';

interface InputNodeProps {
    data: EleganNodeData;
}


const InputNode = ({ data }: InputNodeProps) => {
    return (
        <>
            <div className="node-container">
                <div className="node-container-before-secondary">Input</div>
                <div style={{ display: 'flex' }} className="node-bio">
                    <div>{data.label}</div>
                </div>
            </div>
            <CustomHandle
                type="source"
                position={Position.Right}
                style={{ bottom: 10, top: 'auto', background: '#555' }}
                isConnectable={data.outputNodeCount}
            />
        </>
    );
};

export default memo(InputNode);