import React, { useEffect, useRef, useState } from 'react';
import TablesView from './TableView';
import FlowView, { render } from './FlowView';
import pb from '../../../lib/pocketbase';
import { DestinationRecord, FileRecord, FlowRecord, Mapping, Operation } from '../../../../types/global';
import { Edge, Node, ReactFlowInstance, getIncomers } from 'reactflow';
import { SchemaTableType } from '../Flow';
import ValidationView from './Validation';
import { API_ROUTE } from '../../../lib/env';


interface AnalysisStepProps {
  flowId: string;
  destRecord: DestinationRecord | null;
  communicateContent: SchemaTableType[];
  setCommunicateContent: React.Dispatch<React.SetStateAction<SchemaTableType[]>>;
}

export interface EleganNodeData {
  label: string;
  index: number | null;
  inputType: string | null;
  outputType: string | null;
  inputNodeCount: number
  outputNodeCount: number
  schemaIndex: number | null;
  arguments: (string | number | Operation)[];
  required: boolean | null;

}

export interface EleganNode extends Node {
  data: EleganNodeData;
}
export interface NodesAndEdges {
  nodes: EleganNode[];
  edges: Edge[];
}

interface UpdateResponse {
  tables: SchemaTableType[];
  mapping: Mapping[];
  changes: Record<string, boolean>;
}


const AnalysisStep = ({ flowId, destRecord, communicateContent, setCommunicateContent }: AnalysisStepProps) => {
  const [view, setView] = useState('table');
  const [tablesData, setTablesData] = useState<Array<Array<Array<string>>>>([[[]]]); // New state for table data
  const [fileRecords, setFileRecords] = useState<FileRecord[]>([]);
  const [flowRecord, setFlowRecord] = useState<FlowRecord | null>(null);

  const [nodes, setNodes] = useState<EleganNode[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);

  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance | null>(null);

  const [modifications, setModifications] = useState<NodesAndEdges[]>([]);
  const [rendered, setRendered] = useState<boolean>(false);

  const [changes, setChanges] = React.useState('');
  const [tempTables, setTempTables] = React.useState<SchemaTableType[]>([{ headers: [], rows: [], errors: [] }]);
  const [tempMapping, setTempMapping] = React.useState<Mapping[]>([]);

  // When a user manually makes changes to the columns, when a column change is incoming we prompt whether they want to keep
  // the manual fixes or have it overwritten.
  const [columnsWithManaulFix, setColumnsWithManualFix] = React.useState<boolean[][]>(communicateContent.map(file =>file.headers.map(() => false)));

  const nodeId = useRef(0);

  const getId = () => {
    return `${nodeId.current++}`;
  }
  const [errors, setErrors] = useState<string[]>([]);

  function validateNodes(nodes: EleganNode[], edges: Edge[]): string[] {
    const validationErrors: string[] = [];
    // Get output nodes
    const outputNodes = nodes.filter(node => node.type === 'Output');

    // Iterate them and get incomers
    outputNodes.forEach(outputNode => {
      if (!outputNode.data.required) return;

      const incomers = getIncomers(outputNode, nodes, edges);

      if (incomers.length === 0) {
        validationErrors.push(`Output node ${outputNode.data.label} has no incoming nodes`);
      }
    });
    return validationErrors;
  }

  useEffect(() => {
    const newErrors = validateNodes(nodes, edges);
    setErrors(newErrors);
  }, [])

  useEffect(() => {
    const newErrors = validateNodes(nodes, edges);
    setErrors(newErrors);
  }, [nodes, edges])

  useEffect(() => {
    // Fetch file data
    pb.collection('flows').getOne<FlowRecord>(flowId)
      .then((flow) => {
        if (!flow) return;
        setFlowRecord(flow);

        // Make api calls to get samples for each table
        Promise.all(flow.files.map(file_id =>
          pb.collection('files').getOne<FileRecord>(file_id)
        )).then(tableFiles => {
          setFileRecords(tableFiles);
          setTablesData(tableFiles.map(file => file.sample));

          // Call render function here after fetching all necessary data
          if (destRecord && flow) {
            render(destRecord, flow, tableFiles, getId, setEdges, setNodes);
            setRendered(true);
          }
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const toggleView = (view: string) => {
    setView(view);
  };




  return (
    <>
      <div className="flex">
        <button disabled={view === 'table'} onClick={() => toggleView("table")}>
          Table View
        </button>
        <button disabled={view === 'flow'} onClick={() => toggleView("flow")}>
          ReactFlow View
        </button>
        <button disabled={view === 'validation'} onClick={() => toggleView("validation")}>
          Validation View
        </button>
      </div>
      {view === 'table' ? (
        <TablesView
          flowRecord={flowRecord}
          setFlowRecord={setFlowRecord}
          destRecord={destRecord}
          fileMetadata={fileRecords}
          nodes={nodes}
          setNodes={setNodes}
          edges={edges}
          setEdges={setEdges}
          communicateContent={communicateContent}
          setCommunicateContent={setCommunicateContent}
          errors={errors}
          getId={getId}
        />
      ) : view === 'flow' ? (
        <FlowView
          fileMetadata={fileRecords}
          flowMetadata={flowRecord}
          destRecord={destRecord}
          nodes={nodes}
          setNodes={setNodes}
          edges={edges}
          setEdges={setEdges}
          reactFlowInstance={reactFlowInstance}
          setReactFlowInstance={setReactFlowInstance}
          modifications={modifications}
          setModifications={setModifications}
          rendered={rendered}
          setRendered={setRendered}
          getId={getId}
          errors={errors}
          setErrors={setErrors}
        />
      ) : (
        <ValidationView communicateContent={communicateContent} /> 
      )}    
      <div className="flex">
        <input style={{ flex: 1 }} type="text" placeholder="Search.." onChange={(e) => {
          setChanges(e.target.value);
        }} />
        <input type="submit" value="Search" onClick={(e) => {
          if(!flowRecord || !destRecord) return; // Maybe add message?

          fetch(`${API_ROUTE}/api/transform/update`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${pb.authStore.token}`
            },
            body: JSON.stringify({
              changes: changes,
              nodes: nodes,
              edges: edges,
              flow_id: flowRecord.id,
              destination_id: destRecord.id,
            }),
          })
            .then(response => response.json())
            .then((data: UpdateResponse) => {
              setTempMapping(data.mapping)
              setTempTables(data.tables)
              const schemaTable = document.getElementById('schema_table_0');
              if (!schemaTable) {
                console.log("Could not find schema table")
                return
              }

              const schemaTableHeaders = schemaTable?.querySelector('thead')?.querySelectorAll('th');
              if (!schemaTableHeaders) {
                console.log("Could not find schema table headers")
                return
              }

              schemaTableHeaders.forEach((header, index) => {
                if (header.innerText in data.changes && data.changes[header.innerText]) {
                  // Change the color of the column to red
                  const rows = schemaTable.querySelectorAll('tbody tr');
                  header.style.backgroundColor = 'red'; // Change header color to red
                  rows.forEach(row => {
                    const cell = row.querySelectorAll('td')[index];
                    if (cell) {
                      cell.style.backgroundColor = 'red'; // Change cell color to red
                    }
                  });
                }
              });
            })
        }} />
        {tempTables.map((schemaTable, tableIndex) => {
          if (schemaTable.headers.length === 0) return;

          return (
            <button key={tableIndex} onClick={() => {
              if (!flowRecord || !destRecord) return; // Maybe add message?

              // Sets the specific table by index to the communicateContent
              setCommunicateContent(tempTables);

              // Remove the red color from the headers and cells
              const schemaTable = document.getElementById('schema_table_0');
              if (!schemaTable) {
                console.log("Could not find schema table")
                return
              }

              const schemaTableHeaders = schemaTable?.querySelector('thead')?.querySelectorAll('th');
              if (!schemaTableHeaders) {
                console.log("Could not find schema table headers")
                return
              }

              schemaTableHeaders.forEach((header, index) => {
                header.style.backgroundColor = 'white'; // Change header color to white
                const rows = schemaTable.querySelectorAll('tbody tr');
                rows.forEach(row => {
                  const cell = row.querySelectorAll('td')[index];
                  if (cell) {
                    cell.style.backgroundColor = 'white'; // Change cell color to white
                  }
                });
              });

              flowRecord.mapping = tempMapping;
              render(destRecord, flowRecord, fileRecords, getId, setEdges, setNodes);
              setTempTables([{ headers: [], rows: [], errors: [] }]);


            }}>Apply</button>
          )
        })}
      </div>
      </>
  );
};

export default AnalysisStep;