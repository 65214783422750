import pb from '../lib/pocketbase';
import { SubmitHandler, useForm } from 'react-hook-form';
import useLogout from '../hooks/useLogout';
import useLogin, { FormData } from '../hooks/useLogin';
import logo from '../assets/logo.png';
import { WEB_HOST, WEB_PORT } from '../lib/env'

export default function Auth() {
  const logout = useLogout();
  const { mutate: login, isLoading, isError } = useLogin();
  const { register, handleSubmit, reset } = useForm<FormData>();

  const onSubmit: SubmitHandler<FormData> = (data) => {
    login({ email: data.email, password: data.password });
    reset();
  };

  if (pb.authStore.isValid) {
    return (
      <>
        <h1>Logged In: {pb.authStore.model?.email}</h1>
        <div>
          <p>
            <a href={`http://${WEB_HOST}:${WEB_PORT}/`}>Home Page</a>
          </p>
          <p>
            <a href="http://137.74.35.82:8090/_/">Pocketbase - admin@pocketbase.com - (shared pass)</a>
          </p>
          <p>
            <a href="http://137.74.35.82:15672/">Rabbit MQ - nottheadmin - (shared pass)</a>
          </p>
          <p>
            <a href="http://137.74.35.82:9001/">Minio - minioadmin - minioadmin</a>
          </p>
        </div>
        <button onClick={logout}>Logout</button>
      </>
    );
  }

  return (
      <div className="app-container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="login-header">
            <img src={logo} alt="logo" />
            <h1>Sign In</h1>
          </div>

          <span className="muted">Email</span>
          <div className="login-input-container">
            <input type="text" {...register('email')} />
          </div>

          <span className="muted">Password</span>
          <div className="login-input-container">
            <input type="password" {...register('password')} />
          </div>
          {isError && (
            <p className="muted">
              Invalid credentials, please try again...
            </p>
          )}
          <button
            className="sleek-button"
            type="submit"
            disabled={isLoading}
            value="Submit"
          >
            {isLoading ? 'Loading' : 'Login'}
          </button>
        </form>
      </div>
  );
}