import React from 'react';
import Typography from '@mui/material/Typography';

interface SubmitStepProps {
  flowId: string;
}
const SubmitStep = ({ flowId }: SubmitStepProps) => {
  return (
    <Typography>Step 3: Create an ad</Typography>
  );
};

export default SubmitStep;