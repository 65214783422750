import React, { memo, useRef, useState } from 'react';
import { Handle, Position } from 'reactflow';
import { EleganNode } from '../Analysis';
import CustomHandle from './CustomHandle';

interface ConnectNodeProps {
    data: EleganNode["data"];
    isConnectable: boolean;
}

interface DateConvertionModalProps {
    data: EleganNode["data"];
    setDisplayModal: React.Dispatch<React.SetStateAction<boolean>>;
}

function validateDateFormat(dateString: string): boolean {
    const regex = /^%([aAwdbBmyYHIpMSfzZjUWcCxXG%uV]|[0-9]{2})$/;

    const validSpecifiers = new Set([
        'a', 'A', 'w', 'd', 'b', 'B', 'm', 'y', 'Y', 'H', 'I', 'p', 'M', 'S',
        'f', 'z', 'Z', 'j', 'U', 'W', 'c', 'C', 'x', 'X', '%', 'G', 'u', 'V'
    ]);

    if (!dateString.startsWith('%')) {
        return false;
    }

    const specifier = dateString.slice(1);

    if (specifier.length === 2 && /^[0-9]{2}$/.test(specifier)) {
        return true;
    }

    return regex.test(dateString) && validSpecifiers.has(specifier);
}

const DateConvertionModal = ({ data, setDisplayModal }: DateConvertionModalProps) => {
    // Assuming data.arguments is an array and might be undefined or have fewer elements
    const defaultInputFormat = data?.arguments?.[0] || "";
    const defaultOutputFormat = data?.arguments?.[1] || "";
    const defaultUnmatched = data?.arguments?.[2] || "return_fail";

    if (typeof defaultInputFormat !== "string" || typeof defaultOutputFormat !== "string" || typeof defaultUnmatched !== "string") {
        throw new Error("Invalid arguments");
    }

    const [inputFormatDate, setInputFormatDate] = useState<string>(defaultInputFormat);
    const [outputFormatDate, setOutputFormatDate] = useState<string>(defaultOutputFormat);
    const [unmatched, setUnmatched] = useState<string>(defaultUnmatched);
    return (
        <div style={{ overflowY: 'auto', backgroundColor: 'gray', color: 'white', padding: '10px' }}>
            <h1>Date Convertion</h1>
            <div className="flex">
                <div className='flex' style={{ flexDirection: 'column' }}>
                    <h3>Input format</h3>
                    <input
                        className="main__input"
                        type="text"
                        name="input_format_date"
                        placeholder="%Y-%m-%d %H:%M:%S"
                        onChange={(e) => {
                            setInputFormatDate(e.target.value);
                        }}
                        defaultValue={data.arguments[0] as string}
                    />
                </div>
                <div className='flex' style={{ flexDirection: 'column' }}>
                    <h3>Output format</h3>
                    <input
                        className="main__input"
                        type="text"
                        name="output_format_date"
                        placeholder="%Y-%m-%d %H:%M:%S"
                        onChange={(e) => {
                            setOutputFormatDate(e.target.value);
                        }}
                        onBlur={(e) => {
                            if (!validateDateFormat(e.target.value)) {
                                console.log("Invalid date format");
                            }
                        }}
                        defaultValue={data.arguments[1] as string}
                    />
                </div>
            </div>
            <h3>Unmatched conditions</h3>
            <select id="unmatched" onChange={(e) => {
                setUnmatched(e.target.value);
            }}
                defaultValue={unmatched}
            >
                <option value="return_fail">Fail row</option>
                <option value="return_empty">Continue with empty value</option>
                <option value="return_input">Forward the input value</option>
            </select>
            <br />
            <button
                onClick={() => {
                    if (!inputFormatDate || !outputFormatDate || !unmatched) {
                        console.log(inputFormatDate, outputFormatDate, unmatched)
                        alert("Please fill all the fields");
                        return;
                    }

                    data.arguments = [
                        inputFormatDate,
                        outputFormatDate,
                        unmatched,
                    ]
                    setDisplayModal(false);
                }}
            >
                Save
            </button>
        </div>
    );
};



const ConvertDateNode = ({ data }: ConnectNodeProps) => {
    const [displayModal, setDisplayModal] = useState<boolean>(false);
    return (
        <>
            <CustomHandle
                type="target"
                position={Position.Left}
                style={{ background: '#555' }}
                isConnectable={1}

            />
            <div onDoubleClick={() => {
                console.log("Double click")
                setDisplayModal(true);
            }} className="node-container">
                <div className="node-bio">
                    {data.label}
                </div>

            </div>
            <CustomHandle type="source" position={Position.Right} style={{ background: '#555' }} isConnectable={data.outputNodeCount} />
            {displayModal && <DateConvertionModal data={data} setDisplayModal={setDisplayModal} />}
        </>
    );
};

export default memo(ConvertDateNode);