import { Edge } from 'reactflow';
import { FileRecord, FileHeader, FlowRecord, DestinationRecord, Mapping } from '../../../../types/global';
import { API_ROUTE } from '../../../lib/env';
import './TableView.css';
import { EleganNode } from './Analysis';
import React, { useEffect } from 'react';
import pb from '../../../lib/pocketbase';
import { render } from './FlowView';
import { SchemaTableType } from '../Flow';


interface TablesViewProps {
  flowRecord: FlowRecord | null;
  setFlowRecord: React.Dispatch<React.SetStateAction<FlowRecord | null>>;
  destRecord: DestinationRecord | null;
  fileMetadata: FileRecord[] | null;
  nodes: EleganNode[]; // Add nodes:
  setNodes: React.Dispatch<React.SetStateAction<EleganNode[]>>; // Add setNodes:
  edges: Edge[]; // Add edges:
  setEdges: React.Dispatch<React.SetStateAction<Edge[]>>; // Add setEdges:
  communicateContent: SchemaTableType[];
  setCommunicateContent: React.Dispatch<React.SetStateAction<SchemaTableType[]>>;
  errors: string[];
  getId: () => string;
}

interface TableViewProps {
  fileMetadata: FileRecord[];
}

const TableView = ({ fileMetadata }: TableViewProps) => {
  return (
    <>{
      fileMetadata.map((file: FileRecord, fileIndex: number) => (
        <table key={`table_${fileIndex}`}>
          <thead>
            <tr>
              {file.inputs.map((input: FileHeader, headerIndex: number) => (
                <th key={`table_${fileIndex}_header_${headerIndex}`}>{input.header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {file.sample.map((row, rowIndex) => (
              <tr key={`table_${fileIndex}_row_${rowIndex}`}>
                {row.map((data, dataIndex) => (
                  <td key={dataIndex}>{data}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ))
    }</>);
}



const SchemaTable = ({ content }: { content: SchemaTableType[] }) => {
  if (!content) {
    return <div>Press the load button</div>;
  }
  return (
    <>
      {content.map((table, tableIndex) => (
        <table id={`schema_table_${tableIndex}`} key={`schema_table_${tableIndex}`}>
          <thead>
            <tr>
              {table.headers.map((header, headerIndex) => (
                <th key={`schema_header_${headerIndex}`}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {table.rows.map((row, rowIndex) => (
              <tr key={`schema_row_${rowIndex}`}>
                {row.map((data, dataIndex) => (
                  <td key={dataIndex}>{data}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ))}
    </>
  );

}





const TablesView = ({ flowRecord, setFlowRecord, destRecord, fileMetadata, nodes, setNodes, edges, setEdges, communicateContent, setCommunicateContent, errors, getId }: TablesViewProps) => {
  

  const runFormat = () => {

    if (!fileMetadata || !flowRecord || !destRecord) {
      console.warn('File metadata, flow record, or destination record not found');
      return;
    }
    // Get api_key from url
    const url = new URL(window.location.href);
    const apiKey = url.searchParams.get('api_key');
    if (!apiKey) {
      console.error('API key not found');
      return;
    }

    fetch(`${API_ROUTE}/api/transform/communicate`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Api-Key': apiKey
        },
        body: JSON.stringify({
          flow_id: flowRecord.id,
          destination_id: destRecord.id,
          nodes: nodes,
          edges: edges,
        }),
      }
    ).then(response => response.json())
      .then(data => {
        setCommunicateContent(data);
      });
  }


  useEffect(() => {
    runFormat();
  }, [fileMetadata, flowRecord, destRecord]);


  if (!fileMetadata || !flowRecord || !destRecord) {
    return <div>Loading...</div>;
  }




  return (
    <>
      <div className="tables-container">
        <TableView fileMetadata={fileMetadata} />
        <SchemaTable content={communicateContent} />
      </div>
      <button onClick={runFormat} disabled={errors.length > 0}>Run Format</button>
    </>
  );
}

export default TablesView;
