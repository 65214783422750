import React, { useMemo } from 'react';
import { getConnectedEdges, Handle, Position, useNodeId, useStore } from 'reactflow';
import { EleganNode } from '../Analysis';

const selector = (s: any) => ({
    nodeInternals: s.nodeInternals,
    edges: s.edges,
});

interface CustomHandleProps {
    isConnectable: number
    type: any;
    position: Position
    style: React.CSSProperties;
}
const CustomHandle = (props: CustomHandleProps) => {
    const { nodeInternals, edges } = useStore(selector);
    const nodeId = useNodeId();
    const isHandleConnectable = useMemo(() => {
        const node: EleganNode = nodeInternals.get(nodeId);
        if (!node) return false;

        const connectedEdges = getConnectedEdges([node], edges);
        const handleConnections = connectedEdges.filter(edge =>
            (props.type === 'target' && edge.target === nodeId) ||
            (props.type === 'source' && edge.source === nodeId)
        );

        return handleConnections.length < props.isConnectable;
    }, [nodeInternals, edges, nodeId, props.isConnectable, props.type]);

    return (
        <Handle {...props} isConnectable={isHandleConnectable}></Handle>
    );
};

export default CustomHandle;