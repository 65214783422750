import React, { memo, useState } from 'react';
import { Handle, Position } from 'reactflow';
import { EleganNode, EleganNodeData } from '../Analysis';
import CustomHandle from './CustomHandle';

interface ConnectNodeProps {
    data: EleganNodeData;
}

interface SplitOnDelimiterModalProps {
    data: EleganNodeData;
    setDisplayModal: React.Dispatch<React.SetStateAction<boolean>>;
}


const SplitOnDelimiterModal = ({ data, setDisplayModal }: SplitOnDelimiterModalProps) => {
    // Assuming data.arguments is an array and might be undefined or have fewer elements
    const defaultDelimiter = data?.arguments?.[0] || "";
    const defaultCount = data?.arguments?.[1] || "";
    const defaultUnmatched = data?.arguments?.[2] || "return_fail";

    if (typeof defaultDelimiter !== "string" || typeof defaultCount !== "string" || typeof defaultUnmatched !== "string") {
        throw new Error("Invalid arguments");
    }

    const [delimiter, setDelimiter] = useState<string>(defaultDelimiter);
    const [count, setCount] = useState<string>(defaultCount);
    const [unmatched, setUnmatched] = useState<string>(defaultUnmatched);
    return (
        <div style={{ overflowY: 'auto', backgroundColor: 'gray', color: 'white', padding: '10px' }}>
            <h1>Split on delimiter</h1>
            <div className="flex">
                <div className='flex' style={{ flexDirection: 'column' }}>
                    <h3>Delimiter</h3>
                    <input
                        className="main__input"
                        type="text"
                        placeholder=","
                        onChange={(e) => {
                            setDelimiter(e.target.value);
                        }}
                        defaultValue={data.arguments[0] as string}
                    />
                </div>
                <div className='flex' style={{ flexDirection: 'column' }}>
                    <h3>Limit Splits</h3>
                    <input
                        className="main__input"
                        type="text"
                        placeholder="1"
                        onChange={(e) => {
                            setCount(e.target.value);
                        }}
                        onBlur={(e) => {
                            console.log("Do some validation...");
                        }}
                        defaultValue={data.arguments[1] as string}
                    />
                </div>
            </div>
            <h3>Unmatched conditions</h3>
            <select id="unmatched" onChange={(e) => {
                setUnmatched(e.target.value);
            }}
                defaultValue={unmatched}
            >
                <option value="return_fail">Fail row</option>
                <option value="return_empty">Continue with empty value</option>
                <option value="return_input">Forward the input value</option>
            </select>
            <br />
            <button
                onClick={() => {
                    if (!delimiter || !count || !unmatched) {
                        console.log(delimiter, count, unmatched)
                        alert("Please fill all the fields");
                        return;
                    }

                    data.arguments = [
                        delimiter,
                        count,
                        unmatched,
                    ]
                    setDisplayModal(false);
                }}
            >
                Save
            </button>
        </div>
    );
};


const SplitOnDelimiterNode = ({ data }: ConnectNodeProps) => {
    const [displayModal, setDisplayModal] = useState<boolean>(false);
    return (
        <>
            <CustomHandle
                type="target"
                position={Position.Left}
                style={{ background: '#555' }}
                isConnectable={data.inputNodeCount}

            />
            <div onDoubleClick={() => {
                console.log("Double click")
                setDisplayModal(true);
            }} className="node-container">
                <div className="node-bio">
                    {data.label}
                </div>

            </div>
            <CustomHandle type="source" position={Position.Right} style={{ background: '#555' }} isConnectable={data.outputNodeCount} />
            {displayModal && <SplitOnDelimiterModal data={data} setDisplayModal={setDisplayModal} />}
        </>
    );
};

export default memo(SplitOnDelimiterNode);